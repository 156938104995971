// src/contexts/AppContextProvider.js

import React from 'react';
import { VisibilityProvider } from './VisibilityContext';
import { MapProvider } from './MapContext';
import ProviderComposer from './ProviderComposer';

const AppContextProvider = ({ children }) => {
    return (
        <ProviderComposer contexts={[
            <VisibilityProvider />,
            <MapProvider />
        ]}>
            {children}
        </ProviderComposer>
    );
};

export default AppContextProvider;
