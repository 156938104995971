import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const AudioPlayer = ({ queue, serverURL, sliceQueue }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [userInteractionNeeded, setUserInteractionNeeded] = useState(false);
    const audioRef = useRef(null); // Reference for the audio element

    useEffect(() => {
        if (queue.length > 0 && !isPlaying) {
            playNextAudio();
        }
    }, [queue, isPlaying]);

    const playNextAudio = () => {
        if (queue.length === 0) {
            setIsPlaying(false);
            return;
        }

        const fullAudioPath = serverURL + '/audio/' + queue[0];
        console.log("Playing next audio...", fullAudioPath);

        if (!audioRef.current) {
            audioRef.current = new Audio(fullAudioPath);
        } else {
            audioRef.current.src = fullAudioPath;
        }

        audioRef.current.play().then(() => {
            setIsPlaying(true);
            setUserInteractionNeeded(false);
        }).catch(e => {
            console.error("Error playing the audio file:", e.message);
            setUserInteractionNeeded(true);
        });

        audioRef.current.onended = () => {
            sliceQueue();
            setIsPlaying(false);
        };
    };

    const handleUserInitiatedPlay = () => {
        if (userInteractionNeeded) {
            playNextAudio();
        }
    };

    const toggleMute = () => {
        if (audioRef.current) {
            audioRef.current.muted = !audioRef.current.muted;
            setIsMuted(!isMuted);
        }
    };

    return (
        <div style={{ position: 'fixed', top: 0, right: 0, padding: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            {userInteractionNeeded && (
                <AppBar position="static" color="default" style={{ flex: 1 }}>
                    <Toolbar style={{ justifyContent: 'center' }}>
                        <IconButton onClick={handleUserInitiatedPlay} color="primary">
                            <PlayArrowIcon /> Click to Play
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            <IconButton onClick={toggleMute} color={isPlaying && !isMuted ? 'primary' : 'default'}>
                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
        </div>
    );
};

export default AudioPlayer;