import React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Icon for "Where am I?"
import MapIcon from '@mui/icons-material/Map'; // Icon for "Build a route"
import { useVisibility } from './contexts/VisibilityContext';

const ControlPanel = ({ onWhereAmI, onBuildRoute }) => {
    const { isVisible } = useVisibility();

    // Uncomment if you want to use visibility logic
    // if (!isVisible.ControlPanel) {
    //     return null;
    // }

    return (
        <div style={{
            position: 'fixed', // Make the buttons sticky
            bottom: '110px', // 100 pixels from the bottom
            right: '15px', // 50 pixels from the right
            display: 'flex',
            flexDirection: 'column', // Stack the buttons vertically
            gap: '10px' // Space between buttons
        }}>
            <IconButton
                color="primary"
                onClick={onWhereAmI}
                aria-label="Where am I?"
            >
                <VisibilityIcon fontSize="large" />
            </IconButton>
            <IconButton
                color="secondary"
                onClick={onBuildRoute}
                aria-label="Build a route"
            >
                <MapIcon fontSize="large" />
            </IconButton>
        </div>
    );
};

export default ControlPanel;

