import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useVisibility } from './contexts/VisibilityContext';

const PlacesList = ({ places, onPlaceClick }) => {
  const { visibility, toggleVisibility } = useVisibility();

  if (!visibility.PlacesList) {
    return null; // If not visible according to the context, don't render anything
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Object.values(places).map((place) => (
        <Card 
          key={place.place_id} 
          sx={{ maxWidth: 345, marginBottom: 2 }}
          onClick={() => onPlaceClick(place)} // Call the passed function with the place as an argument
        >
          {place.photoUrl && (
            <CardMedia
              component="img"
              height="140"
              image={place.photoUrl}
              alt={`Photo of ${place.name}`}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {place.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {place.city}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default PlacesList;
