import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, DirectionsRenderer, LoadScript, Marker } from '@react-google-maps/api';
import { useMap } from './contexts/MapContext'; // Import the context hook

const mapContainerStyle = {
  height: '400px',
  width: '100%'
};

const defaultCenter = {
  lat: 40.712776,
  lng: -74.005974, // Coordinates for New York City
};

const MapComponent = ({ mapRef, directionsProp, googleMapsApiKey }) => {
  const { setBounds } = useMap();  // Using context to set bounds
  const [directions, setDirections] = useState(directionsProp);
  const [currentPosition, setCurrentPosition] = useState(defaultCenter); // State for current position
  const [heading, setHeading] = useState(0); // State to store device heading
  const [isLoaded, setIsLoaded] = useState(false); // State to track if the Google Maps library is loaded
  const [markerIcon, setMarkerIcon] = useState(null); // State to store the marker icon

  useEffect(() => {
    setDirections(directionsProp);
    if (!mapRef.current || !directions || !directions.routes || !directions.routes[0]) return;

    // Fit map bounds to the updated route
    const bounds = new window.google.maps.LatLngBounds();
    directions.routes.forEach(route => {
      bounds.extend(route.bounds.northeast);
      bounds.extend(route.bounds.southwest);
    });

    mapRef.current.fitBounds(bounds);
    setBounds(bounds.toJSON()); // Store bounds in context initially
  }, [directionsProp, setBounds, mapRef]);

  useEffect(() => {
    const requestPermissions = async () => {
      // Request permissions for geolocation
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(() => {}, () => {}, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      }

      // Request permissions for device orientation on iOS
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        try {
          const permissionState = await DeviceOrientationEvent.requestPermission();
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation, true);
          }
        } catch (error) {
          console.error('Error requesting device orientation permissions', error);
        }
      } else {
        window.addEventListener('deviceorientation', handleOrientation, true);
      }
    };

    const handleOrientation = (event) => {
      const { alpha, webkitCompassHeading } = event; // Safari uses webkitCompassHeading
      const heading = alpha !== undefined ? alpha : webkitCompassHeading !== undefined ? webkitCompassHeading : 0;
      setHeading(heading);
    };

    // Geolocation API to fetch the current location
    const geoSuccess = position => {
      const newPos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      setCurrentPosition(newPos);
      mapRef.current.panTo(newPos); // Keep the current position centered
    };

    const geoError = error => {
      console.log('Error occurred. Error code: ' + error.code);
    };

    const geoId = navigator.geolocation.watchPosition(geoSuccess, geoError, {
      enableHighAccuracy: true,
      timeout: 5000, // 5 seconds
      maximumAge: 0
    });

    requestPermissions();

    return () => {
      navigator.geolocation.clearWatch(geoId);
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, [mapRef]);

  useEffect(() => {
    if (isLoaded) {
      // Define the markerIcon only after the API has loaded
      setMarkerIcon({
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 5,
        rotation: heading // Rotate icon based on device heading
      });
    }
  }, [isLoaded, heading]); // Depend on isLoaded and heading to update the markerIcon

  const handleBoundsChanged = () => {
    if (mapRef.current) {
      setBounds(mapRef.current.getBounds().toJSON()); // Update bounds when they change
    }
  };

  const centerOnCurrentLocation = () => {
    if (mapRef.current && currentPosition) {
      mapRef.current.panTo(currentPosition);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={googleMapsApiKey}
      libraries={['places', 'geometry', 'drawing']}
      onLoad={() => setIsLoaded(true)}
      onError={(e) => console.error('Error loading Google Maps', e)}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={currentPosition}
          zoom={10}
          onLoad={map => mapRef.current = map}
          onBoundsChanged={handleBoundsChanged}
        >
          {/* Marker with direction indication */}
          <Marker position={currentPosition} icon={markerIcon} />
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      ) : <div>Loading map...</div>}
      <button onClick={centerOnCurrentLocation}>Center on My Location</button>
    </LoadScript>
  );
};

export default MapComponent;