// src/contexts/MapBoundsContext.js
import React, { createContext, useContext, useState } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const [bounds, setBounds] = useState(null);

    return (
        <MapContext.Provider value={{ bounds, setBounds }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => useContext(MapContext);
