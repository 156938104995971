import React from 'react';
import ParentComponent from './container';
import './App.css'; // Optional: Import a CSS file for global styles
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppContextProvider from './contexts/AppContextProvider';


// Create a theme instance if you want to customize it
const theme = createTheme({
  // your theme customizations
});

/*ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);*/

function App() {
  return (
    <AppContextProvider>
    <div className="App">
      <main>
         <ParentComponent />
      </main>
    </div>
    </AppContextProvider>
  );
}

export default App;
