// ParentComponent.jsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import ChatComponent from './ChatComponent';
import AudioPlayer from './audioPlayer';
import MapComponent from './MapComponent';
import ControlPanel from './ControlPanel';
import PlacesList from './PlacesList';

import { GoogleMap, DirectionsRenderer, LoadScript, useLoadScript } from '@react-google-maps/api';
import io from 'socket.io-client';
import { useMap } from './contexts/MapContext'; // Import the context hook
import { useVisibility } from './contexts/VisibilityContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

//const backendUrl = 'https://eleven-bees-call.loca.lt'
//const backendUrl = 'http://localhost:5000';

  function ParentComponent() {
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef(messages); // Create a ref for messages

    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const socketRef = useRef(null);
    const lastAudioRef = useRef(null);
    const [queue, setQueue] = useState([]);
    const [directions, setDirections] = useState(null);

    const { bounds } = useMap(); // Access the bounds from the context
    const [placesInfo, setPlacesInfo] = useState({}); // Dictionary to store places information
    
    const googleMapsApiKey="AIzaSyCYoy4-FeFKys-8uEOZjovlPbRZ2yiVcAk" // Replace with your API key
    const mapRef = useRef(null);
    const { visibility, toggleVisibility } = useVisibility();


    useEffect(() => {
      socketRef.current = io(backendUrl);
      const socket = socketRef.current;

      socket.on('new_message', message => {
        const lastMessage = messagesRef.current[messagesRef.current.length - 1];
  
        if (lastMessage && lastMessage.user === 'Bot' && lastMessage.message === "") {
          // Exit the function to prevent adding another empty message
          console.log("Preventing an empty message from being added.");
          return;
        }
  
        const newMessage = {
          dateTime: new Date().toISOString(),
          user: "Bot",
          message: "",
        };
        
        console.log(newMessage);
  
        setMessages(prevMessages => [...prevMessages, newMessage]);
      });      

      socket.on('assistant_message', message => {
        setMessages((prevMessages) => {
          const lastMessage = { ...prevMessages[prevMessages.length - 1] };
          // Assuming delta updates message content; adjust as needed
          lastMessage.message += message.delta;
    
          return [...prevMessages.slice(0, -1), lastMessage];
        });
      });

      socket.on('places_list', places => {

        const directionsService = new window.google.maps.DirectionsService();

        if (places.length > 1) {
            const waypoints = places.slice(1, places.length - 1).map(place => ({ location: place, stopover: true }));
            const origin = places[0];
            const destination = places[places.length - 1];

            directionsService.route({
                origin,
                destination,
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
          }
      });

      socket.on('audio_ready', data => {
        const fullAudioPath = data.audio_path;
        console.log(`Received audio: ${fullAudioPath}`);
        if (lastAudioRef.current === fullAudioPath) {
            return;
        }
        setQueue(prevQueue => [...prevQueue, fullAudioPath]);
        lastAudioRef.current = fullAudioPath; // Update the ref
      });

      return () => {
          socket.off('assistant_message');
          socket.off('places_list');
          socket.off('new_message');
          socket.off('audio_ready');
      };

  }, []);

  const sendMessage = (msg) => {
    const newMessage = {
      dateTime: new Date().toISOString(),
      user: "Me",
      message: msg,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage])
    const socket = socketRef.current;
    socket.emit('user_message', { instructions: msg });
  };

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        setIsRecording(true);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
      } catch (err) {
        console.error('Error starting audio recording:', err);
      }
    } else {
      alert('Audio recording is not supported in this browser.');
    }
  };

  const sliceQueue = () => {
    const filename = queue[0];
    const socket = socketRef.current;
    socket.emit('cleanup_audio', { file_name: filename });
    setQueue(prevQueue => prevQueue.slice(1));  
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
  
      mediaRecorderRef.current.onstop = async () => { // Note: `async` can't be directly used here
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];
  
        console.log('Audio recorded:', audioBlob);

        if (audioBlob.size < 100) {
          console.warn('Recording is empty or too short, skipping upload.');
          return;
        }

        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        

        try {
          // Since `fetch` is async, we use `await` to wait for the response
          const ApiUrl = backendUrl + '/transcribe';
          const response = await fetch(ApiUrl, {
            method: 'POST',
            body: formData,
          });
  
          // Use `await` to wait for the JSON conversion of the response body
          const data = await response.json();
  
          if (response.ok) {
            console.log('Transcription:', data.transcription);
            //alert(`Transcription: ${data.transcription}`);
            sendMessage(data.transcription);
          } else {
            // If the response is not ok, log the error message
            console.error('Upload failed:', data.error);
            alert(`Error: ${data.error}`);
          }
        } catch (error) {
          // This catch block now correctly catches network errors and errors in the try block
          console.error('Request failed:', error);
          alert('Upload failed. Check console for details.');
        }
      };
    }
  };
  const onBuildRoute = () => {
    console.log("Build a route");
  
  };
    

  const onWhereAmI = () => {
    //Show popup with current location
    console.log("Where am I?");
  
    /*if (!isLoaded || !bounds) {
      console.log("Bounds or Google Maps not loaded");
      return;
    }*/

    // Create a map instance required by Places Service, not attached to the DOM
    const map = new window.google.maps.Map(document.createElement("div"));
    const placesService = new window.google.maps.places.PlacesService(mapRef.current);

    const request = {
        bounds: new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(bounds.south, bounds.west),
            new window.google.maps.LatLng(bounds.north, bounds.east)
        ),
        type: ['tourist_attraction']
    };

    placesService.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const newPlaces = {};
        results.forEach((place) => {
          newPlaces[place.place_id] = {
            name: place.name,
            city: place.vicinity,
            photoUrl: place.photos ? place.photos[0].getUrl() : null
          };
        });
        setPlacesInfo(newPlaces);
        } else {
            console.error("Failed to fetch tourist attractions:", status);
        }
        
    });
    toggleVisibility('Chat');
    toggleVisibility('PlacesList');
  }
  
  const describeThePlace = (place) => {
    const socket = socketRef.current;
    const placeName = place.name + ", " + place.city;
    socket.emit('place_description', { place: placeName });
    //isVisible.Chat = false;
    //isVisible.PlacesList = true;
    toggleVisibility('Chat');
    toggleVisibility('PlacesList');
  }
  
 return (
    <div>
      <MapComponent mapRef={mapRef} directionsProp ={directions} googleMapsApiKey={googleMapsApiKey}/>
      <ChatComponent messages={messages} onSendMessage={sendMessage} isRecording={isRecording} onStartRecording={startRecording} onStopRecording={stopRecording} />
      <AudioPlayer queue={queue} serverURL={backendUrl} sliceQueue={sliceQueue} />
      <ControlPanel onWhereAmI={onWhereAmI} onBuildRoute={onBuildRoute} />
      <PlacesList places={placesInfo} onPlaceClick={describeThePlace}/>
    </div>
  );
}

export default ParentComponent;
