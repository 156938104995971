import React, { createContext, useContext, useState } from 'react';

// Define the default structure of the context's state
const defaultVisibilityState = {
    MapFull: true,
    MapSmall: false,
    Chat: true,
    ControlPanel: true,
    PlacesList: false
};

const VisibilityContext = createContext({
    visibility: defaultVisibilityState,
    toggleVisibility: () => {}
});

export const VisibilityProvider = ({ children }) => {
    const [visibility, setVisibility] = useState(defaultVisibilityState);

    const toggleVisibility = (componentName) => {
        if (Object.hasOwnProperty.call(visibility, componentName)) {
            setVisibility(prev => ({
                ...prev,
                [componentName]: !prev[componentName]
            }));
        } else {
            console.error(`toggleVisibility: No component named '${componentName}' found in visibility state.`);
        }
    };

    return (
        <VisibilityContext.Provider value={{ visibility, toggleVisibility }}>
            {children}
        </VisibilityContext.Provider>
    );
};

export const useVisibility = () => useContext(VisibilityContext);

export default VisibilityContext;  // Optional, export if needed elsewhere