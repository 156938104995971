// src/contexts/ProviderComposer.js

import React from 'react';

function ProviderComposer({ contexts, children }) {
    return contexts.reduceRight(
        (kids, Parent) => React.cloneElement(Parent, { children: kids }),
        children
    );
}

export default ProviderComposer;