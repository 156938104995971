import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, TextField, Button, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { useVisibility } from './contexts/VisibilityContext';

//function ChatComponent() {

function ChatComponent({ messages, onSendMessage, isRecording, onStartRecording, onStopRecording }) {
  const [inputValue, setInputValue] = useState("");
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const { visibility, toggleVisibility } = useVisibility();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

//  const [messages, setMessages] = useState([]);

  const handleSendClick = () => {
    if (inputValue.trim() !== "") {
      //const newMessage = { id: messages.length + 1, text: inputValue };
      //setMessages([...messages, newMessage]);
      onSendMessage(inputValue);
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const startRecording = async () => {
    console.log('Recording started');
    onStartRecording();
    //setIsRecording(true);
    setMessage('');
  }

  const stopRecording = () => {
    console.log('Recording finished'); 
    onStopRecording();
    //setIsRecording(false);
  }  

  if (!visibility.Chat) {
    return null; // If not visible according to the context, don't render anything
  }

  return (
    <Box
    sx={{
      position: 'fixed', // This will fix the position of the chat box
      bottom: 10, // 10px from the bottom
      right: 10, // 10px from the right
      maxWidth: 700,
      width: 'calc(100% - 20px)', // Full width minus padding/margin on smaller screens
      bgcolor: 'background.paper',
      border: '1px solid #ccc', // Optional border
      borderRadius: '4px', // Rounded corners
    }}
  >
    <List sx={{ maxHeight: 300, overflow: 'auto' }}>
      {messages.map((message, index) => (
        <ListItem key={message.dateTime || index} alignItems="flex-start">
          <ListItemText
            primary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ display: 'block', textAlign: message.user === 'Me' ? 'right' : 'left' }}
                >
                  {message.user}
                </Typography>
                {message.message}
              </>
            }
          />
        </ListItem>
      ))}
      <div ref={messagesEndRef} /> {/* Invisible div for scrolling */}
    </List>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        marginTop: 2,
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type a message..."
        value={inputValue}
        onChange={handleInputChange}
        disabled={isRecording}
      />
      {inputValue ? (
        <IconButton color="primary" onClick={handleSendClick}>
          <SendIcon sx={{ fontSize: '2rem' }} />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          onTouchStart={startRecording}
          onTouchEnd={stopRecording}
          sx={{ fontSize: '2rem' }}
        >
          <MicIcon sx={{ fontSize: '2rem' }} />
        </IconButton>
      )}
    </Box>
  </Box>
);
}

export default ChatComponent;
